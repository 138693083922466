.modal-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  background: rgba(207, 199, 203, 0.2);
  backdrop-filter: blur(1px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  z-index: 100000000000;
  overflow: hidden;
}

.modal-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.modal {
  width: 600px;
  height: auto;
  display: block;
  margin-left: -300px;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.modal-wrapper.open .modal {
  margin-top: -10%;
  opacity: 1;
}

@mixin feedback($color) {
  .content {
    text-align: center;
    color: $color;

    .text {
      margin-bottom: 2rem;
      p {
        font-size: 1.3rem;
        font-weight: normal;
        color: black;
      }
    }
  }

  .head {
    overflow: hidden;
    background: $color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 1.3rem;
      margin-left: 1rem;
      color: #fff;
    }
  }

  .close-btn {
    background: $color;
    border-style: none;
    margin: 5px;
    font-size: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.positive-feedback {
  @include feedback(green);
}

.negative-feedback {
  @include feedback(red);
}

.reaction-icon {
  font-size: 60px;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}

div.info-tooltip {
  --bs-tooltip-max-width: 230px;
  --bs-tooltip-bg: #4d4a44;
  --bs-tooltip-opacity: 0.8;
  position: fixed;

  ul {
    text-align: left;
  }
}

.info-tooltip-icon:hover {
  cursor: pointer;
}
