.nav__action {
  background-color: transparent;
  border: none;
  position: relative;
  display: flex;
  width: 100%;
}

.nav__icon {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.minimized {
    min-width: 18px;
    min-height: 18px;
  }
  min-width: 15px;
  min-height: 15px;
}

.nav__item {
  margin-bottom: 0.2em;
  border-radius: 0.25rem;

  &:hover {
    background-color: #efefef;
  }

  .title {
    color: #333;
    font-weight: 500;

    &.with-spacing {
      margin-left: 2em;
    }
  }

  .icon {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;

    color: var(--color, #333);

    &.stroked {
      stroke-width: 5%;
      stroke: var(--color, #333);
    }
  }

  &.minimized {
    width: 45px;
    height: 45px;
  }
}

.nav__link {
  position: relative;

  &.minimized {
    min-height: 3em;
    height: 100%;
  }

  cursor: pointer;

  &.active {
    background-color: var(--color, #007bff);
    border-radius: 0.25rem;

    .icon,
    .title {
      color: #fff;
      stroke: #fff;
    }

    &:hover {
      filter: brightness(107%);
    }
  }
}

.nav__section-root {
  cursor: pointer;
  z-index: 100;
}

.nav__section-header {
  position: relative;
  cursor: pointer;

  &.minimized {
    margin: 0;
    padding: 0;
    padding-left: 1em;
    min-height: 3em;
    width: 100%;
  }

  &.active {
    background-color: var(--bg-color, #007bff20);
    border-radius: 0.25rem;
  }
}

.nav__section-icon {
  &.opened {
    transform: rotate(-90deg);
  }
  stroke-width: 7%;
  stroke: #333;

  position: absolute;
  right: 1em;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  flex-direction: column;

  transition: transform 0.3s ease-in-out;
}

.nav__section-popup {
  left: 4em;
  margin-top: -3.3em;
  width: 270px;

  position: fixed;
  top: 0;

  display: none;

  &.opened {
    display: block;
  }

  transition: opacity 0.3s ease-in-out;
}

.nav__section-popup-content {
  background: #f8f9fa;
  border-radius: 0.5em;
  padding: 1em;
  margin-left: 2em;
  box-shadow: 0px 5px 12px rgb(136 136 136 / 60%);

  max-height: 25em;
  overflow-y: auto;

  li:hover {
    background-color: var(--bg-color, #007bff20);
    border-radius: 0.25rem;
  }
}

.nav__section-content {
  padding-left: 1.7em;
}
