.diff-added-row {
  --bs-accordion-btn-bg: #e6ffec;
  background-color: #e6ffec !important;
}

.diff-removed-row {
  --bs-accordion-btn-bg: #ffebe9;
  background-color: #ffebe9 !important;
}

.diff-changed-row {
  --bs-accordion-btn-bg: #fff8c4;
  background-color: #fff8c4 !important;
}

.cursor-pointer {
  cursor: pointer;
}
