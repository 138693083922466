html {
  font-size: 14px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

// disable increment/decrement arrow button in number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
