.country-waterfall {
  // make scrollbar always visible, without hovering on it
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  tbody {
    display: block;
    overflow: scroll;
    // 10px is the size of scrollbar
    margin-right: -10px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  td {
    word-wrap: break-word;
  }
}

.is-bidding {
  width: 6%;
}

td.is-bidding {
  text-align: center;
}

.placement-currency-val {
  width: 10%;
}

.network-name {
  width: 15%;
}

.country-waterfall-forms {
  display: flex;
  justify-content: space-between;

  .country-select-form {
    flex: 1 1 10%;

    label {
      font-weight: bold;
    }
  }

  .suggestion-area {
    flex: 1 1 10%;
    p {
      margin: 0;
      font-weight: bold;
    }
    .suggestion-form {
      .invalid-feedback {
        position: absolute;
      }

      textarea {
        margin-top: 10px;
      }

      button {
        margin-top: 2%;
        float: right;
      }
    }
  }
}

.arc-name {
  line-height: 0 !important;
}

table.suggestions-table {
  width: 100%;

  td {
    word-break: break-word;
    white-space: pre-wrap;
  }

  th.suggestion {
    width: 45%;
  }

  .time {
    width: 7%;
  }

  .slack-link {
    width: 6%;
  }

  .network {
    width: 6%;
  }

  div.form-switch {
    padding-left: 0;
    text-align: center;
    input.form-check-input {
      transform: scale(1.5);
      margin-left: 0;
    }
  }
}
